<template>
    <div>
       <div id="page-content">	
		   <form
            data-vv-scope="crtAdhocFeeValidate"
            id="crtfeeplans"
            accept-charset="utf-8"
          >
		    		<!-- <div class="dispflex" style="align-items:baseline">
					<h1 class="hdrtitle ml-2" style="margin-top: 0px;">Fees Management</h1>
					
				</div> -->
				<div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Fees Management
       </h4>
      </div>
				<div class="row mt-2">	
					<div class="col-md-12 col-lg-12 col-sm-12">
						<div class="widjet m-2">
							<div class="widjethdr dispflex">
								<div style="width:auto">
									<span class="iconsect blubg" style="padding-left:9px;"><i class="fas fa-exclamation-circle"></i></span>
									<h1>Adhoc Fee</h1>
								</div>	
							</div>
							
							<div class="widjetcontent mt-1 p-3">
								<div class="form-row ">
									
									<div class="form-group input-group col-md-6 mt-3  pr-md-3">						
										<span class="has-float-label">
											<input type="text" name="fpName" :disabled="actionType == 'view'" v-validate="'required'" v-model="adhocFess.planName" class="form-control form-input" id="fpNameid" placeholder="Fee Plan Name" >
											<label
													v-if="errors.first('crtAdhocFeeValidate.fpName')"
													class="vi-error"
													style="top: -1em"
													>Fee Plan Name is required</label
												>
												<label v-else for="fpnameid" >Fee Plan Name <span class="required">*</span></label>
											
										</span>
									</div>
									<div class="form-group input-group col-md-6 mt-3 ">						
										<span class="has-float-label">
											 <select
											   @change="setPlanDate(adhocFess.academicYear)"
												v-model="adhocFess.academicYear"
												class="form-control form-input"
												id="acyearid"
												:disabled="actionType == 'view'"
												v-validate="'required'"
												name="acdyr"
											>
												<option :value="null">-- Select --</option>
												<option
												v-for="(item, index) in academicYearList"
												:key="index"
												:value="item._id"
												>
												{{ item.from }} - {{ item.to }}
												</option>
											</select>
											<label
													v-if="errors.first('crtAdhocFeeValidate.acdyr')"
													class="vi-error"
													style="top: -1em"
													>Academic Year is required</label
												>
											<label v-else for="acyearid"
												>Academic Year <span class="required">*</span></label
											>
											
										</span>
									</div>
									<div class="form-group input-group col-md-6 mt-3  pr-md-3">						
										<span class="has-float-label">
											<input type="date" :disabled="!adhocFess.academicYear" :min="minPlanDate" :max="maxPlanDate" onkeydown="return false" name="billdate" v-validate="'required'" v-model="adhocFess.billingDate"  class="form-control form-input" id="billdateid" placeholder="Billing Date" >
											<label
													v-if="errors.first('crtAdhocFeeValidate.billdate')"
													class="vi-error"
													style="top: -1em"
													>Billing Date is required</label
												>
											<label v-else for="billdateid">Billing Date <span class="required">*</span></label>
										</span>
									</div>
									<div class="form-group input-group col-md-6 mt-3">						
										<span class="has-float-label">
											<input type="date" onkeydown="return false" :disabled="!adhocFess.billingDate && actionType == 'edit'" :min="adhocFess.billingDate" :max="maxPlanDate" name="duedate" v-validate="'required'" v-model="adhocFess.dueDate"  class="form-control form-input" id="duedateid" placeholder="Billing Date" >
											<label
													v-if="errors.first('crtAdhocFeeValidate.duedate')"
													class="vi-error"
													style="top: -1em"
													>Due Date is required</label
												>
											<label v-else for="duedateid">Due Date <span class="required">*</span></label>
										</span>
									</div>
									<!-- <div class="form-group input-group col-md-6 mt-3 ">						
										<span class="has-float-label">
											<select
												:disabled="actionType == 'view'" v-model="adhocFess.remainder" 
												name="Due on"
												class="form-control form-input"
												id="dueonid"
											>
											<option :value=null >  -- Select --  </option>
											<option v-for="(item,index) in remainderDueDates" :key="index" :value="item.value">
												{{ item.label }}
											</option>
											</select>
											
											<label for="reminderid">Reminder </label>
										</span>
									</div> -->
									
									<!-- <div class="form-group input-group col-md-6 mt-3 ">						
										<span class="has-float-label">
											<input type="text" v-if="status == 'Active'" name="reminder"  style="color: green;" disabled v-model="status"  class="form-control form-input" id="reminderid" placeholder="Reminder" >
											<input type="text" v-else name="reminder"  style="color: brown;" disabled v-model="status"  class="form-control form-input" id="reminderid" placeholder="Reminder" >
											
											<label for="reminderid">Status </label>
										</span>
									</div> -->
									
								</div>							
							</div>
							
						</div>
					</div>
				</div>
				<div class="row mt-2">	
					<div class="col-md-12 col-lg-12 col-sm-12">
						<div class="widjet m-2">
							<div class="admintbl mt-1 p-3">
								<table border="1">
									<thead>
										<tr>
											<th scope="col" rowspan="2" style="border-style: solid;text-align: center;">Fee Name<span class="required">*</span></th>
											<th scope="col" rowspan="2" style="border-style: solid;text-align: center;">Quantity<span class="required">*</span></th>
											<th scope="col" rowspan="2" style="border-style: solid;">Actual Amount(one Quantity) ($)<span class="required">*</span></th>
											<th scope="col" colspan="2" style="border-style: solid;" class="text-center">Discount</th>
											<th scope="col" colspan="2" style="border-style: solid;" class="text-center">GST</th>
											<th scope="col" rowspan="2" style="border-style: solid;" class="text-center">Net Amount ($)</th>
											<th scope="col" rowspan="2" style="border-style: solid;" class="text-center" >Action <button class="btn rndbtn" v-if="adhocFess.adhocFeeLineItem.length == 0" @click.prevent="addLineItem"><i class="fas fa-plus-circle" style="padding-top:4px;"></i></button></th>
										</tr>
										<tr>
											
											<th class="text-center" style="border-style: solid;">%</th>
											<th class="text-center" style="border-style: solid;">Amount</th>
											
											<th class="text-center" style="border-style: solid;">Tax Slab <span class="required">*</span></th>
											 <th class="text-center" style="border-style: solid;">Amount</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in adhocFess.adhocFeeLineItem" :key="index" style="border-style: solid;">
											<td data-label="Fee Name" style="border-style: solid;"><input type="text" style="text-align: center;" :disabled="actionType == 'view'" v-validate="'required'" v-model="item.name" :name="index + 'name'" />
											<span class="required" v-if="errors.first(`crtAdhocFeeValidate.${index + 'name'}`)"> Fee Name is required</span>
											</td>
											<td data-label="Quantity" style="border-style: solid;"><input type="number" style="text-align: center;" class="form-control form-input" v-validate="'required|numeric|min:0'" :name="index + 'Quantity'" @change="setQuantyAndAmountvalues(index,'qty')" :disabled="!item.name && actionType == 'view'" v-model="item.quantity" />
											<span class="required" v-if="errors.first(`crtAdhocFeeValidate.${index + 'Quantity'}`)"> Quantity is required</span>
											</td>
											<td data-label="Actual Amount ($)" style="border-style: solid;"><input type="number"  style="text-align: center;" class="form-control form-input" v-validate="'required|numeric|min:0'" :name="index + 'Amount'" @input="setQuantyAndAmountvalues(index,'actAmount')" :disabled="!item.quantity" v-model="item.actualAmount" />
											<span class="required" v-if="errors.first(`crtAdhocFeeValidate.${index + 'tax'}`)">  Amount is required</span>
											</td>
											
											<td data-label="%" style="border-style: solid;"><input  @input="setdiscountAmount(index)" style="text-align: center;" :disabled="actionType == 'view'" class="form-control form-input" v-validate="'numeric|min:0'" :name="index + 'DiscountPercentage'" type="number" v-model="item.discountPercentage" />
											<span class="required" v-if="errors.first(`crtAdhocFeeValidate.${index + 'DiscountPercentage'}`)"> Minimum value is 0</span>
											</td>
											<td data-label="Amount" style="text-align: center;">{{ item.discountAmount }}</td>
											
											<td data-label="%" style="border-style: solid;">
												  <!-- <input :name="index + 'tax'" v-validate="'required'" @input="settaxAmount(index)" type="text" v-model="item.gstTaxSlab" />
											      <span class="required" v-if="errors.first(`crtAdhocFeeValidate.${index + 'tax'}`)"> Tax Slab is required</span> -->

												  <select
												  :disabled="actionType == 'view'"
													class="form-control form-input" 
												   :name="index + 'tax'" 
												   v-validate="'required'" 
												   @change="settaxAmount(index)" 
												   v-model="item.gstTaxSlab" 
												>
													<option
													v-for="(item, index) in taxSlabList"
													:key="index"
													:value="item.value"
													>
													{{ item.label }}
													</option>
												</select>
											    <span class="required" v-if="errors.first(`crtAdhocFeeValidate.${index + 'tax'}`)"> Tax Slab is required</span>

											</td>

											<td data-label="Amount" style="text-align: center;">{{ item.taxamount }}</td>
											<td data-label="Net Amount ($)" class="txtrgt" style="border-style: solid;">{{ item.netAmount }} </td>
											<td data-label="Action" class="text-right" style="border-style: solid;">
												<div style="width:76%;">
													<b-icon icon="plus-circle" variant="primary" style="height: 25px;margin-right: 5px;" :disabled="actionType == 'view'" v-if="adhocFess.adhocFeeLineItem.length == ( index + 1)" @click.prevent="addLineItem"></b-icon>
                                                    <b-icon icon="dash-circle" variant="danger" style="height: 25px" :disabled="actionType == 'view'" @click.prevent="removeLineItem(index)"></b-icon>

												<!-- <button class="btn rndbtn" :disabled="actionType == 'view'" v-if="adhocFess.adhocFeeLineItem.length == ( index + 1)" @click.prevent="addLineItem"><i class="fas fa-plus-circle" style="padding-top:4px;"></i></button>
												<button class="btn rndbtn" :disabled="actionType == 'view'" @click.prevent="removeLineItem(index)"><i class="fas fa-minus-circle" style="padding-top:4px;"></i></button> -->
												</div>
											</td>
										</tr>
										
										<tr>
											<td colspan="6"></td>
											<td ><b> Amount</b></td>
											<td class="txtrgt">{{ getTotalActualAmount() }}</td>
											<td ></td>
										</tr>
										<tr>
											<td colspan="6"></td>
											<td ><b>Discount (-)</b></td>
											<td class="txtrgt">{{ getTotalDiscountAmount() }}</td>
											<td ></td>
										</tr>
										<tr>
											<td colspan="6"></td>
											<td ><b>Total Tax (+)</b></td>
											<td class="txtrgt">{{ getTotalTaxAmount() }}</td>
											<td ></td>
										</tr>
										<tr style="background:#e7e7e7;color: #444444;">
											<td colspan="6"></td>
											<td style="color:#2954A2"><b>Total Amount</b></td>
											<td class="txtrgt"><b>{{ getTotalNetAmount() }}</b></td>
											<td ></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					
				</div>
				<div class="row mt-2">	
					<div class="col-md-12 col-lg-12 col-sm-12">
						<!-- <div class="widjet m-2"> -->
							
							<div class="widjetfooter text-center dkicon">							
									<button  v-if="actionType != 'view'"  @click.prevent="updateAdhocFee" class="btn btnsml" >
									 <span>Update</span></button>
									<button @click.prevent="$router.go(-1)" type="button" class="btn btncl clsmdl">
									   <span>Cancel</span></button>
								</div>
						<!-- </div> -->
						
					</div>
				</div>
				 </form>
			</div>
			
    </div>
</template>
<script>

import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";

export default {
  name: "vi-adhocFees",
  data() {
    return {

      adhocFess: {
		  adhocFeeLineItem: [{
			  name: '',
			  discountAmount: null,
			  quantity: null,
			  actualAmount: null,
			  discountPercentage: null,
			  taxamount: null,
			  gstTaxSlab: null,
			  netAmount: null
		  }],
		  planName: '',
		  academicYear: null,
		  billingDate: null,
		  dueDate: null,
		  remainder: ''
	  },
      academicYearList: [],
	  status: '',
	  minPlanDate: null,
      maxPlanDate: null,
      minMonth: false,
	  actionType: '',
      minFromPlanStartDate: null,
	  taxSlabList: [
		  {
			  label: '0%',
			  value: "0"
		  },
		  {
			  label: '5%',
			  value: "5"
		  },
		  {
			  label: '12%',
			  value: "12"
		  },
		  {
			  label: '18%',
			  value: "18"
		  },
		  {
			  label: '28%',
			  value: "28"
		  }
	  ],
	  remainderDueDates: [{
        label: '1 day',
        value: '1'
      },
      {
        label: '2 days',
        value: '2'
      },
      {
        label: '3 days',
        value: '3'
      },
      {
        label: '4 days',
        value: '4'
      },
      {
        label: '5 days',
        value: '5'
      },
      {
        label: '6 days',
        value: '6'
      },
      {
        label: '7 days',
        value: '7'
      },
      {
        label: '8 days',
        value: '8'
      },
      {
        label: '9 days',
        value: '9'
      },
      {
        label: '10 days',
        value: '10'
      },
      {
        label: '11 days',
        value: '11'
      },
      {
        label: '12 days',
        value: '12'
      },
      {
        label: '13 days',
        value: '13'
      },
      {
        label: '14 days',
        value: '14'
      },
      {
        label: '15 days',
        value: '15'
      },
      {
        label: '16 days',
        value: '16'
      },
      {
        label: '17 days',
        value: '17'
      },
      {
        label: '18 days',
        value: '18'
      },
      {
        label: '19 days',
        value: '19'
      },
      {
        label: '20 days',
        value: '20'
      },
      {
        label: '21 days',
        value: '21'
      },
      {
        label: '22 days',
        value: '22'
      },
      {
        label: '23 days',
        value: '23'
      },
      {
        label: '24 days',
        value: '24'
      },
      {
        label: '25 days',
        value: '25'
      },
      {
        label: '26 days',
        value: '26'
      },
      {
        label: '27 days',
        value: '27'
      },
      {
        label: '28 days',
        value: '28'
      },
      {
        label: '29 days',
        value: '29'
      },
      {
        label: '30 days',
        value: '30'
      },
      {
        label: '31 days',
        value: '31'
      }
      ], 

    };
  },
  created() {
	  this.getAdhocFeeDetails();
	  this.getAcademicYearList();
	  this.checkRouteType();
  },
  methods: {
	previousRoute(){
     let data = localStorage.getItem('previousTab');
      let data2 = localStorage.getItem('activeTab');
     

        if(data2 == '/feesManagement/home'){
          localStorage.setItem("activeTab",'/feesManagement/home');
          localStorage.setItem("previousTab",'/feesManagement/home');
        }else{
           localStorage.setItem("activeTab",data);
          localStorage.setItem("previousTab",data);
        }
     this.$router.go(-1)
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
	checkRouteType(){
     this.actionType = this.$route.params.type
	},
	addLineItem(){
		 this.adhocFess.adhocFeeLineItem.push({
			  name: '',
			  quantity: null,
			  discountAmount: 0,
			  actualAmount: 0,
			  discountPercentage: 0,
			  gstTaxSlab: 0,
			  netAmount: 0
		  })
	},
	getTotalActualAmount(){
			if(this.adhocFess.adhocFeeLineItem.length > 0){
				let totalAmount = 0

				for(let x of this.adhocFess.adhocFeeLineItem){
					if(x.actualAmount){
					totalAmount = (totalAmount + Number(x.actualAmount))
				} 
				}
				return totalAmount

			}else{
				return 0
			}
	},
	getTotalDiscountAmount(){
		if(this.adhocFess.adhocFeeLineItem.length > 0){
			let totalAmount = 0

				for(let x of this.adhocFess.adhocFeeLineItem){
					if(x.discountAmount){
					totalAmount = (totalAmount + Number(x.discountAmount))
				} 
				}
				return totalAmount

		}else{
			return 0
		}
	},
	getTotalTaxAmount(){
		if(this.adhocFess.adhocFeeLineItem.length > 0){
			let totalAmount = 0

				for(let x of this.adhocFess.adhocFeeLineItem){
					if(x.taxamount){
					totalAmount = (totalAmount + Number(x.taxamount))
				} 
				}

				return totalAmount

		}else{
		return 0
		}
	},
	getTotalNetAmount(){
		

		if(this.adhocFess.adhocFeeLineItem.length > 0){

		let totalAmount = 0

        for(let x of this.adhocFess.adhocFeeLineItem){
			if(x.netAmount){
			totalAmount = (totalAmount + Number(x.netAmount))
		  } 
		}
		return totalAmount.toFixed(2)
	}else{
		return 0
	}
	},	
  
	removeLineItem(index){
		 this.adhocFess.adhocFeeLineItem.splice(index,1);
	},
	setdiscountAmount(index){

		const details = this.adhocFess.adhocFeeLineItem[index]

        const quntyAddamount = (details.actualAmount * details.quantity)

		if(details.discountPercentage){
			

			const percent = (quntyAddamount / 100) * details.discountPercentage;
			this.adhocFess.adhocFeeLineItem[index].discountAmount = percent

			if(details.gstTaxSlab){
					const taxAmnt = ((quntyAddamount - percent)  / 100) * details.gstTaxSlab;
			        this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt.toFixed(2);
					this.adhocFess.adhocFeeLineItem[index].netAmount = ((details.actualAmount - percent) + taxAmnt).toFixed(2);
			}else{
               this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount - percent)).toFixed(2);
			}
		}else if(details.gstTaxSlab){
			this.adhocFess.adhocFeeLineItem[index].discountAmount = null
            const taxAmnt = (quntyAddamount  / 100) * details.gstTaxSlab;
			this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt.toFixed(2);
			this.adhocFess.adhocFeeLineItem[index].netAmount = (quntyAddamount + taxAmnt).toFixed(2);
		}
		else{
		 this.adhocFess.adhocFeeLineItem[index].discountAmount = null
         this.adhocFess.adhocFeeLineItem[index].netAmount = (quntyAddamount).toFixed(2);
		}
	},
	setPlanDate(acdyear){
      if(acdyear){
        const selectAcdYear = this.academicYearList.find( x => x._id == acdyear);
        
        let start = new Date(selectAcdYear.from)
        let end = new Date(selectAcdYear.to)
         // start date 
        let startmonth = start.getMonth()+1
        let startdate = start.getDate()
        

        if (startdate < 10) {
          startdate = '0' + startdate;
        }
        if (startmonth < 10) {
          startmonth = '0' + startmonth;
        }

        this.minPlanDate =  start.getFullYear() + "-" + startmonth + "-" + startdate;

        let endmonth = end.getMonth()+2        

    
		let enddate = new Date(end.getFullYear(), endmonth, 0).getDate();

        if (endmonth < 10) {
          endmonth = '0' + endmonth;
        }

        this.maxPlanDate =  end.getFullYear() + "-" + endmonth + "-" + enddate;

      }
    },
	setQuantyAndAmountvalues(index,type){

		const details = this.adhocFess.adhocFeeLineItem[index]
		
		if(type == 'qty'){
			if(details.quantity){
				if(details.quantity && details.actualAmount){
					const quntyAddamount = (details.actualAmount * details.quantity)

					if(details.quantity && details.actualAmount && !details.discountPercentage && !details.discountAmount && !details.gstTaxSlab && !details.taxamount){
                       this.adhocFess.adhocFeeLineItem[index].netAmount = ((details.quantity * details.actualAmount)).toFixed(2);

					}else if(details.quantity && details.actualAmount && details.discountPercentage && details.discountAmount && details.gstTaxSlab && details.taxamount){
					const percent = (quntyAddamount / 100) * details.discountPercentage;
					this.adhocFess.adhocFeeLineItem[index].discountAmount = percent

					const taxAmnt = ((quntyAddamount - percent)  / 100) * details.gstTaxSlab;
			        this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt.toFixed(2);
					this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount - percent) + taxAmnt).toFixed(2);

					}else if(!details.discountPercentage && !details.discountAmount && !details.gstTaxSlab && !details.taxamount){
					  this.adhocFess.adhocFeeLineItem[index].netAmount = quntyAddamount.toFixed(2);

				   }else if(details.discountPercentage && details.discountAmount && !details.gstTaxSlab && !details.taxamount){
				    const percent = (quntyAddamount / 100) * details.discountPercentage;
					this.adhocFess.adhocFeeLineItem[index].discountAmount = percent.toFixed(2);

					this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount - percent)).toFixed(2);

				}else if(!details.discountPercentage && !details.discountAmount && details.gstTaxSlab && details.taxamount){
					const taxAmnt = (quntyAddamount  / 100) * details.gstTaxSlab;
			        this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt.toFixed(2);
					this.adhocFess.adhocFeeLineItem[index].netAmount = (quntyAddamount + taxAmnt).toFixed(2);

				}else{
					this.adhocFess.adhocFeeLineItem[index].discountAmount = null;
					this.adhocFess.adhocFeeLineItem[index].discountPercentage = null;
					this.adhocFess.adhocFeeLineItem[index].taxamount = null;
					this.adhocFess.adhocFeeLineItem[index].gstTaxSlab = null;
					this.adhocFess.adhocFeeLineItem[index].netAmount = null
				}
				}
			}else{
				    this.adhocFess.adhocFeeLineItem[index].discountAmount = null;
					this.adhocFess.adhocFeeLineItem[index].actualAmount = null;
					this.adhocFess.adhocFeeLineItem[index].discountPercentage = null;
					this.adhocFess.adhocFeeLineItem[index].taxamount = null;
					this.adhocFess.adhocFeeLineItem[index].gstTaxSlab = null;
					this.adhocFess.adhocFeeLineItem[index].netAmount = null
			}
		}else{

			if(details.actualAmount){

				const quntyAddamount = (details.actualAmount * details.quantity)

				if(details.discountPercentage && details.discountAmount && details.gstTaxSlab && details.taxamount){

					const percent = (quntyAddamount / 100) * details.discountPercentage;
					this.adhocFess.adhocFeeLineItem[index].discountAmount = percent.toFixed(2);

					const taxAmnt = ((details.actualAmount - percent)  / 100) * details.gstTaxSlab;
			        this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt.toFixed(2);
					this.adhocFess.adhocFeeLineItem[index].netAmount = ((details.actualAmount - percent) + taxAmnt).toFixed(2);


				}else if(!details.discountPercentage && !details.discountAmount && !details.gstTaxSlab && !details.taxamount){
					this.adhocFess.adhocFeeLineItem[index].netAmount = quntyAddamount.toFixed(2);

				}else if(details.discountPercentage && details.discountAmount && !details.gstTaxSlab && !details.taxamount){
				
				    const percent = (quntyAddamount / 100) * details.discountPercentage;
					this.adhocFess.adhocFeeLineItem[index].discountAmount = percent.toFixed(2);

					this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount - percent)).toFixed(2);

				}else if(!details.discountPercentage && !details.discountAmount && details.gstTaxSlab && details.taxamount){
                  
					const taxAmnt = (quntyAddamount  / 100) * details.gstTaxSlab;
			        this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt.toFixed(2);
					this.adhocFess.adhocFeeLineItem[index].netAmount = (quntyAddamount + taxAmnt).toFixed(2);

				}else{

					this.adhocFess.adhocFeeLineItem[index].discountAmount = null;
					this.adhocFess.adhocFeeLineItem[index].discountPercentage = null;
					this.adhocFess.adhocFeeLineItem[index].taxamount = null;
					this.adhocFess.adhocFeeLineItem[index].gstTaxSlab = null;
					this.adhocFess.adhocFeeLineItem[index].netAmount = null
				}

			}else{

			this.adhocFess.adhocFeeLineItem[index].discountAmount = null;
			this.adhocFess.adhocFeeLineItem[index].discountPercentage = null;
			this.adhocFess.adhocFeeLineItem[index].taxamount = null;
			this.adhocFess.adhocFeeLineItem[index].gstTaxSlab = null;
			this.adhocFess.adhocFeeLineItem[index].netAmount = null
			}
		}
	},
	settaxAmount(index){
        
		const details = this.adhocFess.adhocFeeLineItem[index]

		const quntyAddamount = (details.actualAmount * details.quantity)

        if(details.gstTaxSlab){
			if(details.discountPercentage && details.discountAmount){
				const taxAmnt = ((quntyAddamount - details.discountAmount)  / 100) * details.gstTaxSlab;
				this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt.toFixed(2);

			    this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount - details.discountAmount) + taxAmnt)
			}else{
				const taxAmnt = (quntyAddamount  / 100) * details.gstTaxSlab;
				this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt.toFixed(2);
				this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount) + taxAmnt).toFixed(2);
			}
		}else{
			this.adhocFess.adhocFeeLineItem[index].taxamount = null
			if(details.discountPercentage){

				this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount - percent)).toFixed(2);

			}else{
				this.adhocFess.adhocFeeLineItem[index].netAmount = (quntyAddamount).toFixed(2);
				
			}
		}
		
	},
	async getAdhocFeeDetails(){
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
         const response = await ViService.viXGet(`/feesManagement/getAdhocFee?AdhocId=${this.$route.params.id}`,userData.token );
     
        if (response.isSuccess) {

		  const result = secureUI.secureGet(response.data);
          
        
		  this.adhocFess.planName = result.planName,
		  this.adhocFess.academicYear = result.academicYear,
		  this.adhocFess.billingDate = result.billingDate.substring(0,10);
		  this.adhocFess.dueDate = result.dueDate.substring(0,10);
		  this.adhocFess.remainder = result.remainder
	      this.adhocFess.adhocFeeLineItem = result.adhocFeeLineItem
          this.status = result.assignedLineItem.length == 0 ? 'Draft' : 'Active'
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
		   if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
         this.$bvModal.show("showFeestype");
      }
    },
	 async updateAdhocFee() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtAdhocFeeValidate").then((result) => {
        isFormValid = result;
      });

      if(isFormValid){
        let userData = secureUI.sessionGet("user");

        if(!userData){
          this.$toasted.error("Please login and do the action");
        } else {
			if(this.adhocFess.adhocFeeLineItem && this.adhocFess.adhocFeeLineItem.length > 0){
						const response = await ViService.viXPut(`/feesManagement/updateAdhocFee/${this.$route.params.id}`,this.adhocFess,userData.token );

				if (response.isSuccess) {
					this.$toasted.success(response.message);
					this.$router.push("/feesManagement/adhocfees");
				} else {
					this.$toasted.error(response.message);
					if(response.message == 'Your session has expired, please login'){
						localStorage.removeItem('user');
						this.$router.push('/login');
				}
				}
			}else{
				this.$toasted.error('Please add minimum one adhocFeeLineItem');
			}
       
        }
      } else {
        this.$toasted.error(message);
      }
    },
	async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
		   if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
  },
};
</script>